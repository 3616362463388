import React, { Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Spinner } from "@jds/core";
import { relativePath } from "./Constants/config";


const Dashboard = React.lazy(() => import("../src/Components/DashBoard" /* webpackChunkName: "Dashboard" */));
const Home = React.lazy(() => import("../src/Components/Home" /* webpackChunkName: "Home" */));
const Lab = React.lazy(() => import("../src/Components/Lab" /* webpackChunkName: "Lab" */));
const Doctor = React.lazy(() => import("../src/Components/Doctor" /* webpackChunkName: "Doctor" */));
const Patient = React.lazy(() => import("../src/Components/Patient" /* webpackChunkName: "Patient" */));
const NewCorporate = React.lazy(() => import("../src/Components/NewCorporates" /* webpackChunkName: "Corporate" */));
const LoginTemplate = React.lazy(() => import("../src/Components/LoginTemplate" /* webpackChunkName: "Corporate" */));

const routes = [
    {
        path: "/",
        element: <Dashboard/>,
        children : [
            {
                path: "/",
                element: <Home/>,
            },
            {
                path: "/labs",
                element: <Lab/>,
            },
            {
                path: "/doctors",
                element: <Doctor/>,
            },
            {
                path: "/patients",
                element: <Patient/>,
            },
            {
              path: "/corporates",
              element: <NewCorporate/>,
            },
        ]
    },
    {
       path:'/login',
       element:<LoginTemplate path={"/login"}/>
    }
];

const router = createBrowserRouter(routes, {
  basename: relativePath,
})

const Routes = () => {
  return (
    <Suspense fallback={<Spinner style={{marginTop: "45vh"}}/>}>
      <RouterProvider router={router} />
    </Suspense>
  )
}

export default Routes;
