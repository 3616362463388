
export const apimSubscriptionKey = "444da0cf60df4c49b11b4ff5d3dfbe50";
export const bifrostApimSubscriptionKey = "6c7ee1c6e0434070b2dee9955c4b4bdd";
export const relativePath = "/healthhub";
export const jsonS3BucketDomain = "https://staticstoragehubprod-f7c5bbbzaqejayde.a02.azurefd.net/healthhub-healthjio-json/ril/prod";
export const whatsappNumber = "918169581695";
export const GAMeasurementID = 'G-F9NPFK0FJ2';
export const imageUploadBaseUrl = "https://staticstoragehubprod-f7c5bbbzaqejayde.a02.azurefd.net/healthhub-production-mumbai-offer/software_libraries/plug_files/3x/";
export const apimSubscriptionKeyForSS = "444da0cf60df4c49b11b4ff5d3dfbe50"
export const env = "https://api.prod.health.jio.com/";
export const bifrostEnv = "https://bifrost.healthhub.net.in/"
export const hpTnCEnv= "https://production.healthhub.net.in/";
export const envValue = "prod";
export const vaccineFaqEnv = "https://production.healthhub.net.in/";
export const FaqEnv = "https://production.healthhub.net.in/";
export const paymentEnv = "healthhub.jio.com";
export const jioMeetUrl = "https://jiomeetpro.jio.com/";
export const cancelPolicyEnv = "https://productionptapp.healthhub.net.in//v1/patient_api/patient_appointments/%d/cancellation_policy";
export const bookingPolicyEnv = vaccineFaqEnv + "jiohealthhub/covid19_vaccine/tnc";
export const feedbackEnv = vaccineFaqEnv + "offers/covid_vaccination_feedback";
export const environment = "https://healthhub.jio.com";
export const HPEnv = "https://healthhub.jio.com" + "/healthpatri";
export const VaccinationEnv = "https://healthhub.jio.com" + "/vaccination";
export const uploadReportEnv = "https://healthhub.jio.com" + "/upload-reports";
export const jioHealthPlayStoreLink = "https://play.google.com/store/apps/details?id=com.hh.healthhub&referrer=utm_source%3DOrganic%26utm_medium%3DSocial%26utm_term%3DOffer-Share%26utm_campaign%3DInstall%2520by%2520Share%2520Offer%2520";
export const jioHelathAppStoreLink = "https://apps.apple.com/in/app/jiohealthhub/id1056115951";
export const supportedLanguagePath = vaccineFaqEnv + "appintegrationservice/v1/core-platform/localisation/get_config";
export const JHHMainEnv = env + "jhh-webapp-main/";
export const JHHPatientAppEnv = env + "jhh-webapp-patient-app/";
export const JHHUploadEnv = env + "jhh-webapp-upload/";
export const JHHCorePlatform = env + "core-platform/";
export const JHHAppIntegrationService = env + "app-integration-service/";
export const JHHAppNDHMIntegrationServiceV1 = JHHAppIntegrationService + "v1/ndhm-integration-service/";
export const getJHHRequestTokenPath = JHHMainEnv + "api_authentication/request_token"
export const getJHHSendRegistrationOTPPath = JHHMainEnv + "users/send_registration_otp"
export const getJHHRefreshTokenPath = JHHMainEnv + "api_authentication/refresh_token"
export const getJHHRefreshTokenAckPath = JHHMainEnv + "api_authentication/refresh_token_ack"
export const getJHHRegisterDevicePath = JHHMainEnv + "users/register_device"
export const getJHHRequestAccessPath = JHHMainEnv + "v1/users/request_access"
export const getJHHResendRegistrationOTPPath = JHHMainEnv + "users/resend_registration_otp"
export const getJHHSendFamilyAssociationOTPPath = JHHPatientAppEnv + "profile/v1/user_family/send_otp";
export const getVerifyAssociationOTPPath = JHHPatientAppEnv + "profile/v1/user_family/verify_otp";
export const getJHHUpdateProfileDetailsPath = JHHMainEnv + "users/update_profile_details"
export const getFAQList = JHHAppIntegrationService + "v1/core-platform/app_configuration/app_configs";
export const getSessionTokenPath = env + "session-service/api/guest-session"; 
export const getHealthFeedContentPath = env + "session-service/api/session/validate"
export const HealthGetTokenUrl = bifrostEnv + "token/v1/get_token";
export const ContactUsUrl = bifrostEnv + "feedback-service/supportcontactdetails";//@ts-ignore
export const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
export const fallbackErrorMessage =
  "Something went wrong. Please try again later!";

export const fallbackErrorMessageFor5XXStatusCode =
  "Unable to reach server currently. Please try after some time.";